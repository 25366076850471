centered<template>
  <div>
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h4 id="top">Market Areas Index</h4>
        <router-link :to="{ name: 'MarketAreaNew'}" tag="button" class="btn btn-success" type="button">Create Market Area</router-link>
      </div>


    <div class="card">
      <div class="rainbow-background"></div>
      <b-checkbox id="users-archived" v-model="filters.is_archived" v-on:input="getMarketAreas(currentPage, perPage)" switch>
        View Archived Market Areas
      </b-checkbox>
      <!-- Table Headers and Search Bar -->
      <ransack-filtering :headerWidth="33" :searchParam="searchParam" :tableHeaders="headers" @onChange="setFilters" />

      <div class="table-responsive">
        <table class="card-table table">
          <tbody>
          <tr v-for="market_area in market_areas" :key="market_area.id" :id="market_area.id">
            <td class="text-left">
              {{ market_area.name }}
            </td>
            <td class="w-50">
              <div class="btn-group float-right">
                <router-link :to="{ name: 'MarketAreaEdit', params: { id: market_area.id } }" tag="button" class="btn btn-secondary" type="button">Edit</router-link>
                <router-link :to="{ name: 'MarketAreaShow', params: { id: market_area.id } }" tag="button" class="btn btn-primary" type="button">Show</router-link>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <!-- Pagination -->
      <pagination :totalRows="totalRows" :page="currentPage" :numOfRecords="perPage" @onChange="onChange" />

    </div>
  </div>
</template>

<script>

import { MarketAreaService } from '@/common/services/market_area.service';
import { FilterService } from '@/common/services/filter.service';
import Pagination from '@/common/components/pagination.vue';
import RansackFiltering from '@/common/components/ransack-filtering.vue';

export default {
  name: 'MarketAreaIndex',
  components: {
    pagination: Pagination,
    RansackFiltering,
  },
  data() {
    return {
      market_areas: null,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      totalRecords: 0,
      headers: ['Name'],
      searchParam: 'name_cont',
      filters: FilterService.defaultData(),
      isAdmin: false,
      isOwner: false,
      isEmployee: false,
    };
  },
  created() {
    this.getMarketAreas(this.currentPage, this.perPage);
  },
  methods: {
    onChange(page, perPage) {
      this.getMarketAreas(page, perPage);
    },
    getMarketAreas(page, perPage) {
      const params = FilterService.build({ page, per_page: perPage }, this.filters);
      MarketAreaService.query(params).then((response) => {
        this.currentPage = parseFloat(response.headers['x-page']);
        this.totalRows = parseFloat(response.headers['x-total']);
        this.market_areas = response.data;
      });
    },
    setFilters(filters) {
      this.filters = filters;
      this.getMarketAreas(this.currentPage, this.perPage);
    },
  },
};
</script>

<style lang="scss" scoped>
  /*@import "../../styles/views/market_areas";*/
</style>
